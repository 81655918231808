.grid-container {
  width: 95%;
  margin: auto;
}

.grid-buttons {
  width: 100%;
  margin-top: 0.5em;
}

.btn-reset {
  float: left;
}

.btn-import {
  float: right;
}
