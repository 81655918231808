.alert-head .alert-title {
  text-decoration: none;
  font-weight: normal;
  font-size: 1.6em;
}

.alert-wrapper {
  border-radius: 28px;
}

.alert-button-inner {
  text-transform: none;
}

.alert-button-role-destructive .alert-button-inner {
  color: #ff5449;
}
